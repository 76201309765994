import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef, Renderer2, AfterViewInit, } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { NguCarouselConfig } from '@ngu/carousel';
import * as moment from 'moment';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { HomeService } from 'src/app/@core/services/home.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { LeaveBalanceHRService } from 'src/app/@core/services/leave-balance-hr.service';
import { MyAttendenceCalenderService } from 'src/app/@core/services/my-attendence-calender.service';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';
import { HomeSettingQuicklinkService } from 'src/app/@core/services/homesettingquicklink.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements AfterViewInit {
  @ViewChild('carouselItems', { static: false }) carouselItemsRef!: ElementRef;
  // Loaders
  dashboard_employee_loader: boolean = false
  loader_announce: boolean = false
  loader_attendance : boolean = false
  loader_quick: boolean = false
  loader_announce_scroll: boolean = false
  dashboard_balance_loader: boolean = false
  dashboard_attendance_loader: boolean = false
  noYesterDay: boolean = false
  noSummary: boolean = false
  noLeave: boolean = false
  // Pagination
  limit = 5
  offset = 0
  // EMP Data Variables
  employee_id: any;
  userDataSession: any
  employee_shift: any
  checkinFlag: boolean = true
  webCheckIn: boolean = false
  // Timer
  hh: any = '00:00:00'
  startTimer: any = '00:00:00'
  endTimer: any = '00:00:00'

  timer: any
  accumulatedDuration: moment.Duration = moment.duration(0);
  // Announcement Variables
  announce_length = 0
  announcementList: any = []
  noannounce: boolean = false
  announceData: any
  inboxPending: any
  inboxUnread: any
  requestPending: any
  DOJ: any
  // Yesterday Attendance Variables
  yesterDayData: any
  // Leave Balance Variables
  balanceData: any = []
  // Attendance Summary Variables
  currentMonth = new Date().toLocaleString('en-us', { month: 'long' })
  listOfMonth = ["January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"];
  summaryData: any
  currentYear = Number(new Date().getFullYear())
  salaryYears: any
  summaryTitle: any
  // Quick Link Variables
  manageQuickLink = false;
  requestValidationData: any
  investmentCnt: any
  tax_regima: any
  proposed_cnt: any
  confirmed_cnt: any
  alertToggle: boolean = false
  alertMsg: any
  // FBP
  // FBP
  fbdeclarationFlag = false;
  fbpRequestView = false;
  fbpReqViewData: any = [];
  serverOffset:any = '';
  addProfileModal = false;
  captureModal = false;
  verificationModal = false;

  WebCamDataUrl = ""
  VerifyText = ""
  VerifyClass = ""
  retake = false
  failed  = 0
  failedmodal  = false


  ownBday:any         = [];
  empBday:any         = [];
  empJoinee:any       = [];
  ownAnniversary:any  = [];
  empAnniversary:any  = []
  removebday          = true
  removeAnniversary   = true
  notificationPanel   = false
  selectedList:any    = []
  selectedName        = "";
  loaderBday         = false
  loaderAnniversary  = false
  loaderJoinee       = false
  quicklinkdata      : any=[];

  public carouselTileItems: Array<any> = [];

  calculateTotalWork = false
  public quickLinkTiles: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 3, lg: 4, all: 0 },
    slide: 3,
    speed: 250,
    point: {
      visible: true
    },
    load: 2,
    velocity: 0,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };
  public leaveBalanceTiles: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 3, lg: 4, all: 0 },
    slide: 3,
    speed: 250,
    point: {
      visible: true
    },
    load: 2,
    velocity: 0,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };
  constructor(
    private homeService: HomeService,
    public userData: AuthService,
    private attendanceService: MyAttendenceCalenderService,
    private cd: ChangeDetectorRef,
    public appService: AppService,
    private requestServ: InvestmentDeclerationService,
    private messageService: MessageService,
    public router: Router,
    private renderer: Renderer2,
    private leaveService: LeaveBalanceHRService,
    private HomeSettingQuicklinkService: HomeSettingQuicklinkService,


  ) {
    this.checkinLiveUpdate();
    this.inboxLiveUpdate();
   }
  ngOnInit() {


    this.getquicklink();
    // EMP Data
    this.employee_id = this.userData.isEmployeeId
    this.dashboard_employee_loader = true
    // API calls If user logins in
    if (this.employee_id != 0) {
      this.userDataAPI()
      // this.initialCall = true
      // Announcement Summary API
      this.getAnnouncementSummary()
      // Yesterday Attendance API
      this.yesterdayAttendance()
      // Leave Balance API
      this.getLeaveBalanceData()
      // Web Check In
      this.webShift()
      // Attendance Summary
      this.attendanceSummary()
      // Request Redirection
      this.requestEligibility()
      this.attendanceService.checkinSocket();
      this.getBdayList();
      this.getAnniversaryList();
      // New Joinee
      this.getNewJoinee();
      // Inbox Count Websocket
      this.homeService.InboxCountSocket()
    }
  }
  ngOnDestroy() {
    this.attendanceService.closeSocket();
    this.homeService.closeSocket()
  }
  ngAfterViewInit() {
    this.onScroll(); // Call onScroll once the view has been initialized
  }
  currentServerDate()
  {
      return moment().add('milliseconds', this.serverOffset);
  }

  inboxLiveUpdate(){
    this.homeService.inbox_item.subscribe(result =>{
      this.inboxUnread = result?.event.total_unread
      this.inboxPending = result?.event.pending
      this.requestPending = result?.event.pending_request_counts
    })
  }

  onScroll() {
    if (this.carouselItemsRef?.nativeElement) {
      const carouselItems = this.carouselItemsRef?.nativeElement;
      const scrollLeft = carouselItems.scrollLeft;
      const maxScrollLeft = carouselItems.scrollWidth - carouselItems.clientWidth;
      const leftButton = document.getElementById('carousel-left');
      const rightButton = document.getElementById('carousel-right');
      if (leftButton) {
        this.renderer.setStyle(leftButton, 'display', scrollLeft <= 0 ? 'none' : 'flex');
      }
      if (rightButton) {
        this.renderer.setStyle(rightButton, 'display', scrollLeft >= maxScrollLeft ? 'none' : 'flex');
      }
    }
  }
  scrollRight() {
    if (this.carouselItemsRef?.nativeElement) {
      const carouselItems = this.carouselItemsRef?.nativeElement;
      const scrollOffset = 200; // Adjust this value for desired scroll distance
      carouselItems.scrollTo({
        left: carouselItems.scrollLeft + scrollOffset,
        behavior: 'smooth'
      });
    }
  }
  scrollLeft() {
    if (this.carouselItemsRef?.nativeElement) {
      const carouselItems = this.carouselItemsRef?.nativeElement;
      const scrollOffset = 200; // Adjust this value for desired scroll distance
      carouselItems.scrollTo({
        left: carouselItems.scrollLeft - scrollOffset,
        behavior: 'smooth'
      });
    }
  }
  // User
  userDataAPI() {
    this.homeService.getPersonalSummary(this.employee_id).subscribe((res: any) => {
      this.userDataSession = res.data
    })
  }
  // checkinLiveUpdate
  checkinLiveUpdate() {
    this.attendanceService.checkin_item.subscribe(result => {
      this.employee_shift?.punches.push(result.event)
      this.checkinFlag = result.event?.punch_inout == null ? true : result.event?.punch_inout
      this.getFirstCheckin(this.employee_shift?.punches).then(res => this.updateTime());
    })
  }
  // Web Check In & Shift API
  webShift() {
    this.attendanceService.getWebcheckinValidations(this.employee_id).subscribe((res: any) => {
      this.serverOffset = moment(res?.output?.current_date_time).diff(new Date());
      this.employee_shift = res?.output
      this.salaryYears = Object.keys(res?.output?.dropdown_data)
      this.DOJ = res?.output?.date_of_joined
      this.webCheckIn = (this.employee_shift?.is_attendance_capture_configed && this.employee_shift?.is_attendance_rule_configed && this.employee_shift?.web_checkin )
      this.checkinFlag = res?.output?.last_punch_details == null ? true : res?.output?.last_punch_details?.punch_inout

      this.endTimer = res?.output?.last_punch_details?.punch_inout ? moment(res?.output?.punches[res?.output?.punches?.length - 1].punch_date + " " + res?.output?.punches[res?.output?.punches?.length - 1].punch_time, 'YYYY-MM-DD HH:mm:ss') : this.currentServerDate()

      this.calculateTotalWork = res?.output?.calculate_total_work
      this.getFirstCheckin(res?.output?.punches).then(res => this.updateTime());
      this.dashboard_employee_loader = false
      this.checkinLoader = false
      this.cd.detectChanges()
    })
  }

  getFirstCheckin(punches: any = []) {
    let firstCkeckinFound = false
    return new Promise<void>((resolve, reject) => {
      for (let punch of punches) {
        if (punch?.punch_inout == false && !firstCkeckinFound) {
          firstCkeckinFound = true
          this.startTimer = moment(punch.punch_date + " " + punch.punch_time, 'YYYY-MM-DD HH:mm:ss')
        }
      }
      resolve();
    });
  }
  checkinLoader = false


  checkSelfie(){
    if(this.employee_shift.is_selfie_enabled && (this.employee_shift.save_photo_checkin_from_web || this.employee_shift.verify_photo_checkin_from_web)){
      if(this.failed  == 3){
        this.failedmodal = true
        this.verificationModal = true
        this.VerifyText = "Unavailable because of 3 failed attempts. Try again after 30 seconds."
      } else
        this.captureModal=true
    } else {
      this.checkin_out()
    }
  }

  pictureTaken(DataUrl:any){
    if(this.employee_shift.verify_photo_checkin_from_web){
      this.verifyPhoto(DataUrl)
    } else {
      this.checkinFlag = !this.checkinFlag
      this.captureModal=false
      this.attendanceService.createAttendenceMark(this.employee_id, { 'punch_inout': this.checkinFlag,'dataURL':DataUrl,'name':'webcamimage.jpeg' }).subscribe((res: any) => {
        this.webShift()
      })
    }

  }
  verifyPhoto(DataUrl:any){
    this.failedmodal  = false
    this.WebCamDataUrl = DataUrl

    this.verificationModal = true
    this.VerifyClass = "progress-pending"
    this.VerifyText = "Kindly wait, we are verifying the images..."

    this.retake = false
    this.captureModal=false

    this.checkinFlag = !this.checkinFlag
    this.checkinLoader = true
    this.attendanceService.createAttendenceMark(this.employee_id, { 'punch_inout': this.checkinFlag,'dataURL':DataUrl,'name':'webcamimage.jpeg' }).subscribe((res: any) => {

      this.verificationModal = true
      this.VerifyText = "Verification successful. The faces are matching."
      this.VerifyClass = "progress-success"

      this.webShift()
    },
    (err: any) => {
      // console.log(err)
      this.checkinFlag = !this.checkinFlag
      this.verificationModal = true
      this.checkinLoader = false
      this.failed++
      this.VerifyClass = "progress-danger"
      let bal:any = 3-this.failed
      if(err.error.error == 'Not verified')
        this.VerifyText = "Verification failed. The faces are not matching. You have "+bal+" more attempts remaining."
      else if(err.error.error == 'Profile image not added')
        this.VerifyText = "Employee image not found in SkizzleHR server. You have "+bal+" more attempts remaining."
      this.retake = true
      if(this.failed == 3){
        setTimeout(() => {this.failed = 0},3000)
      }

    })
  }
  retakeFn(){
    if(this.failed  == 3){
      this.failedmodal = true
      this.verificationModal = true
      this.VerifyText = "Unavailable because of 3 failed attempts. Try again after 30 seconds."
    } else{
      this.captureModal=true
      this.verificationModal = false;
    }
  }
  // Check In
  checkin_out() {
    this.checkinFlag = !this.checkinFlag
    this.checkinLoader = true

    this.attendanceService.createAttendenceMark(this.employee_id, { 'punch_inout': this.checkinFlag }).subscribe((res: any) => {
      this.webShift()
      if (this.checkinFlag) {
      clearTimeout(this.timer)
      this.cd.detectChanges()
    }
    })
  }
  // Updating the time
  updateTime() {

    if (this.calculateTotalWork == false) {
      this.hh = this.calcFirstInLastOut()
    } else {
      this.hh = this.everyInOut()
    }

    this.timer = setTimeout(() => {
      this.updateTime();
    }, 100);
    this.cd.detectChanges()
  }
  everyInOut() {
    let len = this.employee_shift?.punches.length
    let loopCount = Math.ceil(len / 2)
    let sum = 0
    let duration: any
    let start: any
    let end: any
    for (let index = 0; index < loopCount; index++) {
      start = moment(this.employee_shift?.punches[index * 2]?.punch_date + " " + this.employee_shift?.punches[index * 2]?.punch_time);
      end = (len == index * 2 + 1) ? this.currentServerDate() : moment(this.employee_shift?.punches[index * 2 + 1]?.punch_date + " " + this.employee_shift?.punches[index * 2 + 1]?.punch_time);;
      duration = moment.duration(end.diff(start))
      sum += duration.asMilliseconds()
    }
    return moment.utc(sum).format("HH:mm:ss");
  }
  calcFirstInLastOut() {
    let duration: any
    let sum = 0

    if (this.checkinFlag)
      duration = moment.duration(this.endTimer?.diff(moment(this.startTimer, 'YYYY-MM-DD HH:mm:ss')))
    else
      duration = moment.duration(this.currentServerDate()?.diff(this.startTimer))
    sum = (duration.isValid()) ? duration.asMilliseconds() : 0
    return (sum != 0) ? moment.utc(sum).format("HH:mm:ss") : '00:00:00';
  }
  // Request Eligiblity
  requestEligibility() {
    this.requestServ.requestDetails().subscribe((res: any) => {
      this.requestValidationData = res?.count_info
      if (res?.count_info?.hasOwnProperty('max_count_limit')) {
        this.investmentCnt = res?.count_info.max_count_limit;
      } if (res?.count_info?.hasOwnProperty('assigned_salary_data')) {
        if (res?.count_info?.assigned_salary_data?.hasOwnProperty('tax_regime')) {
          this.tax_regima = res?.count_info?.assigned_salary_data?.tax_regime;
        } else {
          this.tax_regima = res?.count_info?.assigned_salary_data?.AssignSalaryStructure;
        }
      }
      if (res?.count_info?.hasOwnProperty('request_count_info')) {
        if (res?.count_info?.request_count_info?.hasOwnProperty('Proposed_Investment_Declaration')) {
          this.proposed_cnt = res?.count_info?.request_count_info.Proposed_Investment_Declaration;
        } if (res?.count_info?.request_count_info?.hasOwnProperty('Confirmed_Investment_Declaration')) {
          this.confirmed_cnt = res?.count_info?.request_count_info?.Confirmed_Investment_Declaration;
        }
      }
    })
  }
  // Quick Links
  redirectRoute(link: any) {
    let currentYear: any = new Date().getFullYear();
    let currentMonth: any = new Date().toLocaleString('default', { month: 'long' });
    let currentMonthNumber = new Date(Date.parse(currentMonth + " 1, " + currentYear + "")).getMonth() + 1;
    let setYear: any;
    if (link == 'Confirmed Investment' || link == 'Proposed Investment') {
      this.alertToggle = true;
      if (this.requestValidationData?.statutory_tax_settings == 'Not tax settings configured') {
        if(link == 'Confirmed Investment')
          this.alertMsg = 'Confirmed investment declaration cannot be raised as tax settings has not been configured yet.'
        if(link == 'Proposed Investment')
          this.alertMsg = 'Proposed investment declaration request cannot be raised as tax settings has not been configured yet.'
      }
      else if (!this.requestValidationData.is_payroll_configed) {
        if(link == 'Confirmed Investment')
          this.isConfigured('payrollConfig','Confirmed investment declaration');
        if(link == 'Proposed Investment')
          this.isConfigured('payrollConfig','Proposed investment declaration');
      }
      else if (this.tax_regima == 'AssignSalaryStructure matching query does not exist.' || this.tax_regima == 'Salary structure not assigned') {
        if(link == 'Confirmed Investment')
          this.isConfigured('salaryStructure','Confirmed investment declaration');
        if(link == 'Proposed Investment')
          this.isConfigured('salaryStructure','Proposed investment declaration');
      }
      else if(this.requestValidationData?.statutory_tax_settings?.is_employee_under_flat_rate){
        if(link == 'Confirmed Investment')
          this.isConfigured('flattax','Confirmed investment declaration');
        else if(link == 'Proposed Investment')
          this.isConfigured('flattax','Proposed investment declaration');
      }
      else {
        this.alertToggle = false;
        if (link == 'Confirmed Investment') {
          // Payroll configuration for confirmed start
          if (this.requestValidationData?.hasOwnProperty('payroll_config_data')) {
            let fyMonthNumber = new Date(Date.parse(this.requestValidationData.payroll_config_data.confirmed_declaration_fy_month + " 1, " + currentYear + "")).getMonth() + 1;
            let todate = new Date();
            var ci = [1, 2, 3].indexOf(currentMonthNumber);
            var fi = [1, 2, 3].indexOf(fyMonthNumber);
            if (ci != -1 && fi != -1) {
              setYear = currentYear;
              // CD => 9-1-2023 FD => 12-2-2023
            }
            else if (ci == -1 && fi == -1) {
              // CD => 9-12-2023 FD => 12-12-2023
              setYear = currentYear;
            }
            else if (ci == -1 && fi != -1) {
              // CD => 9-12-2023 FD => 12-2-2024
              setYear = Number(currentYear) + 1;
            }
            else if (ci != -1 && fi == -1) {
              // CD => 9-1-2023 FD => 12-12-2022
              setYear = this.appService.getFinancialYear();
            }
            let fromdate = (new Date(setYear + "-" + fyMonthNumber + "-" + this.requestValidationData.payroll_config_data.confirmed_declaration_fy_date));
            if (moment(todate).format('YYYY-MM-DD') > moment(fromdate).format('YYYY-MM-DD')) {
              this.alertToggle = true;
              this.alertMsg = 'The last date for submitting confirmed investment declaration for the current financial year has lapsed, please contact your HR.'
            }
            else if (this.requestValidationData.payroll_config_data?.is_today_in_proposed_cuttoff_range && !(this.requestValidationData.is_payslip_released)) {
              this.isConfigured('cutOffData', 'confirmed investment declaration');
            }
            else if (this.requestValidationData?.is_confirmed_it_declaration_pending) {
              this.isConfigured('limitExceed', 'confirmed investment declaration');
            }
            else {
              if (this.requestValidationData?.statutory_tax_settings?.max_con_investment_declare_request >= 0 && (this.requestValidationData?.statutory_tax_settings?.max_con_investment_declare_request <= this.confirmed_cnt)) {
                this.alertToggle = true;
                this.alertMsg = 'You cannot raise confirmed investment declaration request as you have exceeded the request limit for this month.';
              } else {
                this.router.navigate(['/confirmed-investment-decleration']);
              }
            }
          }
        }
        else if (link == 'Proposed Investment') {
          if (this.requestValidationData?.hasOwnProperty('payroll_config_data')) {
            let fyMonthNumber = new Date(Date.parse(this.requestValidationData.payroll_config_data.proposed_declaration_fy_month + " 1, " + currentYear + "")).getMonth() + 1;
            let todate = new Date();
            var ci = [1, 2, 3].indexOf(currentMonthNumber);
            var fi = [1, 2, 3].indexOf(fyMonthNumber);
            if (ci != -1 && fi != -1) {
              setYear = currentYear;
              // CD => 9-1-2023 FD => 12-2-2023
            }
            else if (ci == -1 && fi == -1) {
              // CD => 9-12-2023 FD => 12-12-2023
              setYear = currentYear;
            }
            else if (ci == -1 && fi != -1) {
              // CD => 9-12-2023 FD => 12-2-2024
              setYear = Number(currentYear) + 1;
            }
            else if (ci != -1 && fi == -1) {
              // CD => 9-1-2023 FD => 12-12-2022
              setYear = this.appService.getFinancialYear();
            }
            let fromdate = (new Date(setYear + "-" + fyMonthNumber + "-" + this.requestValidationData.payroll_config_data.proposed_declaration_fy_date));
            if ((moment(todate).format('YYYY-MM-DD') > moment(fromdate).format('YYYY-MM-DD')) && (!this.requestValidationData.payroll_config_data.proposed_allow_submit_aftercuttoff)) {
              this.alertToggle = true;
              this.alertMsg = 'The last date for submitting proposed investment declaration for the current financial year has lapsed, please contact your HR.';
            }
            else if (this.requestValidationData.payroll_config_data?.is_today_in_proposed_cuttoff_range && !(this.requestValidationData.is_payslip_released)) {
              this.isConfigured('cutOffData', 'proposed investment declaration');
            }
            else if (this.requestValidationData?.is_proposed_it_declaration_pending) {
              this.isConfigured('limitExceed', 'proposed investment declaration');
            }
            // Payroll configuration for proposed end
            else {
              if (this.requestValidationData?.statutory_tax_settings?.max_pro_investment_declare_request >= 0 && (this.requestValidationData?.statutory_tax_settings?.max_pro_investment_declare_request <= this.proposed_cnt)) {
                this.alertToggle = true;
                this.alertMsg = 'You cannot raise proposed investment declaration request as you have exceeded the request limit for this month.';
              } else {
                this.router.navigate(['/proposed-investment-decleration']);
              }
            }
          }
        }
      }
    }
    else if (link == 'On Duty Request') {
      if (!this.requestValidationData.on_duty) {
        this.alertToggle = true
        this.alertMsg = 'As per policy, You are not eligible for On-duty request';
      } else if(!this.requestValidationData.attend_rule_assigned) {
        this.alertToggle = true
        this.alertMsg = 'On-duty request cannot be raised as attendance rule has not been assigned yet.';
      }else{
        this.router.navigate(['/on-duty-setup']);
      }
    }
    else if (link == 'Short Leave Request') {
      if (!this.requestValidationData.short_leave_configured) {
        this.alertToggle = true
        this.alertMsg = 'Short leave request cannot be raised as short leave policy has not been configured yet.'
      } else if(!this.requestValidationData?.attend_rule_assigned){
        this.alertToggle = true;
        this.alertMsg = 'Short leave request cannot be raised as attendance rule has not been configured yet.';
      }else {
        this.router.navigate(['/short-leave-setup']);
      }
    }
    else if (link == 'Leave Request') {
      if(!this.requestValidationData?.leave_configured){
        this.alertToggle = true
        this.alertMsg = 'Leave request cannot be raised as Leave policy is not configured yet.';
      }else if(!this.requestValidationData?.attend_rule_assigned){
        this.alertToggle = true
        this.alertMsg = 'Leave request cannot be raised as attendance rule has not been assigned yet.';
      }else{
        this.router.navigate(['/leave-request-setup']);
      }
    }
    else if (link == 'WFH Request') {
      if (!this.requestValidationData?.is_wfh_allowed) {
        this.alertToggle = true;
        this.alertMsg = 'As per policy, You are not eligible for WFH request.';
      } else if(!this.requestValidationData?.attend_rule_assigned){
        this.alertToggle = true;
        this.alertMsg = 'WFH request cannot be raised as attendance rule has not been assigned yet.';
      }else{
        this.router.navigate(['/work-from-home-setup']);
      }
    }
    else if (link == 'Attendance Regularization Request') {
      if(!this.requestValidationData?.is_attend_regular_allowed){
        this.alertToggle = true
        this.alertMsg = 'As per policy, You are not eligible for Attendance Regularization request.';
      }
      else if (!this.requestValidationData?.attend_rule_assigned) {
        this.alertToggle = true;
        this.alertMsg = 'Attendance Regularization request cannot be raised as attendance rule has not been assigned yet.';
      } else {
        this.router.navigate(['/regularization-setup']);
      }
    }
    else if (link == 'Reimbursement Request') {
      if (this.requestValidationData?.statutory_tax_settings == 'Not tax settings configured') {
        this.isConfigured('taxSettings', 'Reimbursement');
      }
      else if (!this.requestValidationData?.is_payroll_configed) {
        this.isConfigured('payrollConfig', 'Reimbursement');
      }
      else if (this.tax_regima == 'AssignSalaryStructure matching query does not exist.' || this.tax_regima == 'Salary structure not assigned') {
        this.isConfigured('salaryStructure', 'Reimbursement');
      }
      else if (this.requestValidationData?.hasOwnProperty('payroll_config_data')) {
        if (this.requestValidationData?.payroll_config_data?.is_today_in_reimbursemen_cuttoff_range
          && !(this.requestValidationData.is_payslip_released)) {
          this.isConfigured('cutOffData', 'reimbursement');
        }
        else
          this.router.navigate(['/reimbursement-setup']);
      }
    }
    else if (link == 'FBP Declaration') {
      if (this.requestValidationData?.statutory_tax_settings == 'Not tax settings configured') {
        this.isConfigured('taxSettings', 'FBP');
      }
      else if (!this.requestValidationData.is_payroll_configed) {
        this.isConfigured('payrollConfig', 'FBP');
      } else if (this.tax_regima == 'AssignSalaryStructure matching query does not exist.' || this.tax_regima == 'Salary structure not assigned') {
        this.isConfigured('salaryStructure', 'FBP');
      }
      else if (this.requestValidationData?.hasOwnProperty('payroll_config_data')) {
        if (this.requestValidationData?.is_FBP_restricted) {
          this.isConfigured('cutOffData', 'FBP');
        }
        else
          this.fbpReq()
      }
    }
    else if (link == 'my Reimbursement') {
      this.router.navigate(['/reimbursement']);
    }
    else if (link == 'Taxslip') {
      this.router.navigate(['/taxslip']);
    }
    else if (link == 'Payslip') {
      this.router.navigate(['/payslip']);
    }
    else if (link == 'Salary Structure') {
      this.router.navigate(['/my-salary-structure']);
    }
  }
  isConfigured(type: any, reqType: any) {
    this.alertToggle = true;
    if(type ==  'salaryStructure')
      this.alertMsg = reqType+' request cannot be raised as salary structure has not been assigned yet.';
    else if(type == 'payrollConfig')
      this.alertMsg = reqType+' request cannot be raised as payroll settings has not been configured yet.';
    else if(type == 'cutOffData')
      this.alertMsg = 'The '+reqType+' request window has closed and will reopen on the beginning of next pay cycle.';
    else if(type == 'limitExceed')
      this.alertMsg = this.messageService.requestRestriction('Employee');
    else if(type == 'taxSettings')
      this.alertMsg = reqType+' request cannot be raised as tax settings has not been configured yet.';
    else if(type == 'notConfigured')
      this.alertMsg = 'You cannot raise a '+reqType+' request, as it is not configured';
    else if(type == 'flattax')
      this.alertMsg = 'The '+reqType+' is restricted for your employment type, please contact HR for more details.';
  }
  closeInfo(bool: any) {
    this.alertToggle = false;
  }
  fbpReq() {
    this.fbdeclarationFlag = true;
  }
  fbpReqClose(data: any) {
    this.fbdeclarationFlag = data;
    this.loader_quick = true
    setTimeout(() => {
      this.loader_quick = false
    }, 600)
    this.cd.detectChanges()
  }
  fbpReqSaveFunction(data: any) {
    this.loader_quick = true
    this.requestServ.fbpRequestCreate({ 'fbp_request_category': data.fbp_request_category }).subscribe((res: any) => {
      this.fbdeclarationFlag = false;
      this.loader_quick = false
      this.cd.detectChanges()
    });
  }
  // Announcement
  getAnnouncementSummary() {
    if (this.offset == 0) {
      this.loader_announce = true
    }
    this.homeService.getAnnouncementSummary(this.employee_id, this.limit, this.offset).subscribe((res: any) => {
      this.announcementList = [];
      if (this.offset == 0) {
        this.announceData = [];
      }
      for (let item of res.results) {
        this.announcementList.push(item)
      }
      let length = res.results.length
      this.addItems(length)
      this.inboxPending = res.inbox_pending
      this.inboxUnread = res.unread_count
      this.requestPending = res?.request_pending_count
      if (this.announceData?.length === 0) {
        this.noannounce = true
      }
      else {
        this.noannounce = false
      }
    })
  }
  addItems(itemlist: any) {
    this.loader_announce = false
    this.loader_announce_scroll = false
    for (let i = 0; i < itemlist; ++i) {
      this.announceData.push(this.announcementList[i]);
    }
    this.announce_length = this.announceData.length
    this.cd.detectChanges()
  }
  onScrollDown() {
    this.loader_announce_scroll = true
    this.offset += this.limit;
    if (this.announce_length) {
      this.getAnnouncementSummary()
    }
  }

  // Yesterday Attendance
  yesterdayAttendance() {
    this.loader_attendance = true
    this.loader_quick = true
    this.homeService.getYesterdayAttendance(this.employee_id).subscribe((res: any) => {
      this.yesterDayData = res?.data
      this.loader_attendance = false
      this.loader_quick = false
      this.noYesterDay = ((res?.data.work_hour == null && res?.data.first_punch == null && res?.data.last_punch == null) || res?.data.work_hour == 'None')
      this.cd.detectChanges()
    })
  }

  // Leave Balance
  getLeaveBalanceData() {
    this.dashboard_balance_loader = true
    this.leaveService.getEditHistoryView(this.employee_id, new Date().getFullYear(),'profile').subscribe((res: any) => {
      this.balanceData = res?.body?.data
      this.noLeave = res?.body?.data?.length == 0 ? true : false
      this.dashboard_balance_loader = false
      this.cd.detectChanges()
    })
  }

  // Percentage Calc based on Balance
  getBalancePercent(taken: any, balance: any) {
    let percentage;
    if (Number(taken) == 0 || Number(balance) == 0) {
      percentage = 0
    } else {
      percentage = ((taken / balance) * 100)
    }
    let val = Number(percentage.toFixed(2))
    return val
  }

  // Attendance Summary
  changeCarousel(key: any, field: any) {
    if (field == 'month') {
      this.currentMonth = key
    } else {
      this.currentYear = key
    }
    this.attendanceSummary()
  }
  attendanceSummary() {
    this.dashboard_attendance_loader = true
    this.homeService.getAttendanceSummary(this.employee_id, this.currentMonth, this.currentYear).subscribe((res: any) => {
      this.noSummary = res?.data?.is_attendance_rule_exist
      // Getting month number
      var monthNumber = moment(this.currentMonth, "MMMM").month() + 1;
      // getting current date and time
      var currentDate = this.currentServerDate();
      // selected full date
      var selectedDate = this.currentServerDate().year(this.currentYear).month(monthNumber - 1);
      // last day if selected month
      const lastDayOfMonth = this.currentServerDate().month(monthNumber - 1).endOf('month').date();
      // condition to show data along with attendance summary
      this.summaryTitle = monthNumber == Number(new Date().getMonth()) + 1 && currentDate.year() == this.currentYear ? `today` : ` ${lastDayOfMonth}${lastDayOfMonth == 31 ? 'st' : 'th'} ${this.currentMonth.slice(0, 3).toUpperCase()} `
      // condition for checking the date is after current month, then it should show no data
      let selectDateFormat = selectedDate.format('YYYY-MM-DD')
      let currentDateFormat = currentDate.format('YYYY-MM-DD')
      if (moment(selectDateFormat).isAfter(moment(currentDateFormat))) {
        this.noSummary = false
      }
      else if (moment(this.DOJ).isAfter(selectedDate, 'month')) {
        this.noSummary = false
      }
      else {
        this.summaryData = res.data
        this.noSummary = res?.data?.is_attendance_rule_exist
      }
      this.dashboard_attendance_loader = false
      this.cd.detectChanges()
    })
  }
  getBdayList(){

    if(this.homeService.NEEDBDAYWIDGET==false)
    this.removebday = true;

      this.loaderBday = true;
    this.homeService.getBdayList().subscribe((res:any)=>{

      let emp = this.employee_id;

      if(res.data.length>0){
          this.removebday = !this.homeService.NEEDBDAYWIDGET
        this.empBday = res.data
        var selfBday =  res.data.filter(function(data:any) {

          return data.id == emp;
        });

        this.ownBday  = selfBday

        }
        else
        this.removebday = !this.homeService.NEEDBDAYWIDGET

      this.loaderBday = false;

    })

  }
  getAnniversaryList(){
    if(this.homeService.NEEDANNIVERSARY==false)
    this.removeAnniversary = true;

      this.loaderAnniversary = true;

    this.homeService.getAnniversaryList().subscribe((res:any)=>{

      let emp = this.employee_id;
      if(res.data.length>0){
          this.removeAnniversary = !this.homeService.NEEDANNIVERSARY
        this.empAnniversary = res.data;
        var selfAnniversary =  res.data.filter(function(data:any) {

          return data.id == emp;
        });
        this.ownAnniversary = selfAnniversary
      }
        else
        this.removeAnniversary = !this.homeService.NEEDANNIVERSARY

      this.loaderAnniversary = false;

    })

  }
  closePanel(data:any){
    this.notificationPanel = false
  }
  viewDetails(key:any){

    this.notificationPanel = true

    if(key == 'bday'){
      this.selectedList = this.empBday;
      this.selectedName = 'Birthdays'
    }else if(key == 'announce'){
      this.selectedList = this.announceData
      this.selectedName = 'Announcements'
    }else if(key == 'new_joiners'){
      this.selectedName = 'New Joiners'
      this.selectedList = this.empJoinee
    }else
    {
      this.selectedList = this.empAnniversary;
      this.selectedName = 'Work Anniversaries'
    }
  }
  removeBdayAnniversary(type:any){
    if(type=='bday')
    this.homeService.NEEDBDAYWIDGET = false
    else
    this.homeService.NEEDANNIVERSARY = false;
  }

  getNewJoinee(){
    this.loaderJoinee = true
    this.homeService.getnewJoinee().subscribe((res:any)=>{
      this.empJoinee = res?.data
      this.loaderJoinee = false
    })
  }

  getquicklink(){
    this.HomeSettingQuicklinkService.getListAPI('home').subscribe((res:any)=>{
      this.quicklinkdata = res?.body?.data[0]?.QuickLinks_data;
      this.quicklinkdata.forEach((value:any) => {
      if(value.button_enable){
        this.carouselTileItems.push({
            name:  value?.name,
            class: value?.class_name,
            icon:  value?.icon,
        })
       }
      });
    })
  }
}
