import { Component, OnInit } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormArray,FormBuilder,Validators,FormGroup } from '@angular/forms';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { ActivatedRoute, Router,Params } from '@angular/router';
import { AppService } from 'src/app/app.global';
import { DatePipe,Location } from '@angular/common';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { AuthService } from 'src/app/@core/auth/auth.service';
import { PreviousRouteService } from 'src/app/@core/services/previous-route.service';
import { ManageActivityService } from 'src/app/@core/services/manage-activity.service';

@Component({
  selector: 'app-proposed-investment-decleration-setup',
  templateUrl: './proposed-investment-decleration-setup.component.html',
  styleUrls: ['./proposed-investment-decleration-setup.component.scss']
})

export class ProposedInvestmentDeclerationSetupComponent implements OnInit {
  active                = 1;
  cityDropDown          = [];
  investDetails         = [];
  name:any              = '';
  taxRegima: any        = false;
  secTab:any            = false;
  tax_chapt2 : any      = '';
  tax_deduction : any   = '';
  investmentCnt:any     = [];
  proposed_cnt:any      = [];
  confirmed_cnt:any     = [];
  employeeAge:number    = 0;
  tot_amount            = false;
  currency:any;
  alertShow             = 'show';
  loader                = true;
  limitData             = [];
  empId                 = 0;
  taxRegimeData:any     = [];
  investmentDetails:any = [];
  from:any
  tab2touched = false;
  tab3touched = false;
  tab4touched = false;
  tab5touched = false;
  tab6touched = false;
  requestFor:any = ''

  investmentDeclarationForm: FormGroup = this.formBuilder.group({
    hra80gg: this.formBuilder.group({
      hra80gg                           : this.formBuilder.array([])
    }),

    chapt2: this.formBuilder.group({
      employee_contribute_pf            : false,
      employee_contribute_pf_amount     : [''],
      contribution_vpf                  : false,
      contribution_vpf_amount           : [''],
      contribution_15y_ppf              : false,
      ppf_amount                        : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      ppf_document                      : [''],
      previous_employer_pf              : false,
      previous_employer_pf_amount       : [''],
      five_year_POTD                    : false,
      POTD_amount                       : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      POTD_document                     : [''],
      subscribe_national_saving         : false,
      nsc_amount                        : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      nsc_document                      : [''],
      deduction_contribute_NPS          : false,
      nps_amount                        : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      nps_document                      : [''],
      equity_linked_saving              : false,
      ELSS_amount                       : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      ELSS_document                     : [''],
      ULIP                              : false,
      ULIP_amount                       : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      ULIP_document                     : [''],
      interest_NSC                      : false,
      interest_NSC_amount               : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      interest_nsc_document             : [''],
      child_education_tuition_fees      : false,
      tuition_fees                      : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      tuition_fees_document             : [''],
      repay_house_principal             : false,
      repay_house_amount                : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      repay_house_document              : [''],
      stampduty_purchase_house          : false,
      stampduty_amount                  : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      stampduty_document                : [''],
      saukanya_samridhi_yojana          : false,
      ssy_amount                        : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      ssy_document                      : [''],

      nabard_rural_bond                 : false,
      nabard_rural_bond_amount          : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      nabard_rural_bond_document        : [''],
      lic                               : false,
      lic_amount                        : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      lic_document                      : [''],
      annuity                           : false,
      annuity_amount                    : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      annuity_document                  : ['']
    }),

    deductions: this.formBuilder.group({

      employee_additional_contribution          : false,
      employee_contribution_amount              : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      employee_contribution_document            : [''],
      employer_contribution                     : false,
      employer_contribution_amount              : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      employer_contribution_document            : [''],
      tta_ttb                                   : false,
      tta_ttb_amount                            : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      tta_tta_document                          : [''],
      interest_education_loan                   : false,
      interest_education_amount                 : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      interest_education_document               : [''],
      medical_treatment_handicapped             : false,
      disabled_severely                         : true,
      handicapped_dependent_amount              : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      handicapped_dependent_document            : [''],
      medical_expenditure                       : false,
      year_60_more                              : true,
      medical_expenditure_amount                : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      medical_expenditure_document              : [''],
      issue                                     : [],
      self_suffering_disability                 : false,
      self_disabled_severely                    : true,
      self_suffering_amount                     : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      self_suffering_document                   : [''],
      donate_80g                                : this.formBuilder.array([]),
      royalty_patent                            : false,
      royalty_patent_amount                     : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      royalty_patent_document                   : [''],
      contribution_individual_policalparties    : false,
      contribution_policalparties_amount        : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      contribution_policalparties_document      : [''],
      gga80                                     : false,
      cash_other_80gga                          : false,
      deduction_80gga                           : this.formBuilder.array([]),
      total_amount                              : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      transaction_80gga_document                : [''],


      health_insurance_selfspouse80d            : false,
      health_insurance_selfspouse80d_age        : true,
      health_insurance_selfspouse80d_amount     : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      health_insurance_selfspouse80d_document   : [''],
      health_insurance_parents80d               : false,
      health_insurance_parents80d_age           : true,
      health_insurance_parents80d_amount        : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      health_insurance_parents80d_document      : [''],
      health_checkup_selfspouse80d              : false,
      health_checkup_selfspouse80d_amount       : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      health_checkup_selfspouse80d_document     : [''],
      health_checkup_parents80d                 : false,
      health_checkup_parents80d_amount          : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      health_checkup_parents80d_document        : [''],
      medicalbill_senior80d                     : false,
      medicalbill_senior80d_amount              : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      medicalbill_senior80d_document            : [''],
      interest_home_loan_80eea                  : false,
      homeloan80eea_option1                     : false,
      homeloan80eea_option2                     : false,
      homeloan80eea_option3                     : false,
      interest_home_loan_amount80eea            : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      interest_home_loan_document80eea          : [''],
      interest_electrical_vehicle_loan_80eeb    : false,
      interest_electrical_vehicle_loan_amount   : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      interest_electrical_vehicle_loan_document : [''],
      interest_home_loan_80ee                   : false,
      homeloan80ee_option1                      : false,
      homeloan80ee_option2                      : false,
      homeloan80ee_option3                      : false,
      homeloan80ee_option4                      : false,
      interest_home_loan_amount80ee             : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      interest_home_loan_document80ee           : [''],
      donate_social_cause100                    : false,
      donate_social_cause100_amount             : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      cash_other100                             : true,
      fund_type100                              : [],
      transaction_document100                   : [''],
      donate_social_cause_50                    : false,
      donate_social_cause50_amount              : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      cash_other50                              : true,
      fund_type50                               : [],
      transaction_document50                    : [''],
      donate_80g100                             : this.formBuilder.array([]),
      donate_80g50                              : this.formBuilder.array([]),
    }),

    incomeloss: this.formBuilder.group({
      incomefrom_self_occupiedproperty           : false,
      intreston_houseloan_amount                 : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      incomeself_lender                          : this.formBuilder.array([]),
      incomefrom_letoutproperty                  : false,
      loss_lender                                : this.formBuilder.array([]),

    }),

    otherincome: this.formBuilder.group({

      incomefrom_othersource                : false,
      othersource_amount                    : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      othersource_document                  : [''],
      interest_earned_sbaccount             : false,
      sb_account_amount                     : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      sb_account_document                   : [''],
      source_80TTA_80TTB                    : false,
      source_80TTA_80TTB_amount             : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      source_80TTA_80TTB_document           : [''],
      intrest_earned                        : false,
      intrest_earned_amount                 : ['',[digitdecimalValidator(),Validators.maxLength(15)]],
      intrest_earned_document               : [''],

    }),
  });

  constructor(
    private formBuilder:FormBuilder,
    private investmentService:InvestmentDeclerationService,
    public router: Router,
    public route:ActivatedRoute,
    private appservice:AppService,
    private datePipe:DatePipe,
    private authService:AuthService,
    private _location: Location,
    private previousRouteService: PreviousRouteService,
    private activityService:ManageActivityService

  ) { }

  ngOnInit(): void {
    let userData  = this.appservice.getEmpData();
    this.route.params.subscribe((params: Params) => {
      if(params['id']){
       this.empId = params['id']
       this.from  = 'behalf'
       this.activityService.employeeBasicData(this.empId).subscribe((res:any)=>{
        this.requestFor = "";
        if(res['first_name'] != null)
        this.requestFor+= res['first_name']+" ";
        if(res['middle_name'] != null)
        this.requestFor+= res['middle_name']+" ";
        if(res['last_name'] != null)
        this.requestFor+= res['last_name'];
        this.requestFor+=" ("+res['employee_code']+")"
        this.currency = res.currency

      })
      }
      else{

        this.empId       = this.authService.isEmployeeId;
        this.currency = userData.currency;

      }

    })
    this.salaryStructureConfig();

    this.investmentService.cityDropdownList().subscribe((res:any)=>{
      this.cityDropDown = res;
    });

    this.limitList();

  }

  backClicked() {
    this.investmentService.backClicked('/proposed-investment-decleration');
  }

  /********* MAX LIMIT FUNCTIONS ****************/
  limitList(){
    this.investmentService.investLimitList().subscribe((res:any)=>{
      this.limitData = res;
    });
  }

  /********* TAX REGIME CALCULATION FUNCTIONS ****************/
  taxRegimeCalculationFunction(){
    this.investmentDeclarationFormDataModificationFunction();

    let save_data = {'proposed_confirm':false, 'employee':this.authService.isEmployeeId, 'hra80gg': this.investmentDeclarationForm.getRawValue().hra80gg.hra80gg, 'chapter4a80c': this.investmentDeclarationForm.getRawValue().chapt2,'chapter4adeduction': this.investmentDeclarationForm.getRawValue().deductions,'incomeloss_property': this.investmentDeclarationForm.getRawValue().incomeloss,'income_from_othersource': this.investmentDeclarationForm.getRawValue().otherincome}
    if(this.from=='behalf'){
      this.investmentService.taxRegimeCalculation(this.empId, {"data":save_data},'').subscribe((res:any)=>{
        this.taxRegime(res)
      });
    }
    else{

    this.investmentService.taxRegimeCalculation(this.empId, {"data":save_data},'').subscribe((res:any)=>{
        this.taxRegime(res)

      });
    }
  }
  taxRegime(res:any){
    this.taxRegimeData = res.data;
    this.active        = 6;
    this.tab6touched   = true;
  }
  /********* STRING TO NUMBER CONVERSION FUNCTIONS ****************/
  stringToNumberConvertionFunction(){
    let array = ['othersource_amount','sb_account_amount','source_80TTA_80TTB_amount','intrest_earned_amount'];
    if(array.length>0){
      for(let i=0;i<array.length;i++){
        if(this.investmentDeclarationForm['controls'].otherincome.get(array[i])?.value == '' || this.investmentDeclarationForm['controls'].otherincome.get(array[i])?.value == null){
          this.investmentDeclarationForm['controls'].otherincome.get(array[i])?.setValue(0);
        }else{
          this.investmentDeclarationForm['controls'].otherincome.get(array[i])?.setValue(Number(this.investmentDeclarationForm['controls'].otherincome.get(array[i])?.value))
        }
      }
    }
  }

  /********* DECARATION FORM DATA MODIFICATION FUNCTIONS ****************/
  investmentDeclarationFormDataModificationFunction(){

    this.hr80ggSetvalue();
    this.chapt2Setvalue('zero');
    this.deductionsSetvalue('zero');
    this.otherincomeSetvalue('zero');
    this.incomeLossSetValue('zero');

    // To create donate_80g array
    let deductionDonate_80g:any =  [];
    deductionDonate_80g         = [ ...this.investmentDeclarationForm.getRawValue().deductions.donate_80g100, ...this.investmentDeclarationForm.getRawValue().deductions.donate_80g50];
    this.removeAllDonate_80gs();
    if(deductionDonate_80g.length > 0){
      for(let k=0; k<deductionDonate_80g.length; k++){
        this.addDonate_80g();
      }
      (<FormArray>this.investmentDeclarationForm.get("deductions")?.get("donate_80g")).reset(deductionDonate_80g);
    }

    if(this.investmentDeclarationForm.getRawValue().deductions.donate_80g.length > 0){
      for(let k=0;k<this.investmentDeclarationForm.getRawValue().deductions.donate_80g.length;k++){
        let final_from_Date3 = (this.investmentDeclarationForm.getRawValue().deductions.donate_80g[k]['payment_date'] != null) ? (this.appservice.dateFormatConvert(this.investmentDeclarationForm.getRawValue().deductions.donate_80g[k]['payment_date'])) : null;
        (<FormArray>this.investmentDeclarationForm.get("deductions")?.get("donate_80g")).at(k).get('payment_date')?.setValue(final_from_Date3);
      }
    }
  }

  /********* SUBMIT FORM FUNCTIONS ****************/
  submitOne(){
    this.active = 2;
    this.tab2touched = true;
  }

  submitTwo(){
    this.active = 3;
    this.tab3touched = true;
  }

  submitThree(){
    this.active = 4;
    this.tab4touched = true;
  }

  submitFour(){
    this.active = 5;
    this.tab5touched = true;
  }

  submitForm(){
    this.taxRegimeCalculationFunction();
  }

  /********* FINAL SAVE FUNCTION ****************/
  finalSubmit(data:any){

    this.hr80ggSetvalue();
    this.chapt2Setvalue('zero');
    this.deductionsSetvalue('zero');
    this.otherincomeSetvalue('zero');
    this.incomeLossSetValue('zero');

    this.investmentDeclarationFormDataModificationFunction();

    // To convert the date formate
    if(this.investmentDeclarationForm.getRawValue().deductions.deduction_80gga.length > 0){
      for(let k=0;k<this.investmentDeclarationForm.getRawValue().deductions.deduction_80gga.length;k++){
        let final_from_Date2 = (this.investmentDeclarationForm.getRawValue().deductions.deduction_80gga[k]['date'] != null) ? (this.appservice.dateFormatConvert(this.investmentDeclarationForm.getRawValue().deductions.deduction_80gga[k]['date'])) : null;
        (<FormArray>this.investmentDeclarationForm.get("deductions")?.get("deduction_80gga")).at(k).get('date')?.setValue(final_from_Date2);
      }
    }
    let saveData:any = {'proposed_confirm':false, 'employee':null, 'hra80gg': this.investmentDeclarationForm.getRawValue().hra80gg.hra80gg, 'chapter4a80c': this.investmentDeclarationForm.getRawValue().chapt2,'chapter4adeduction': this.investmentDeclarationForm.getRawValue().deductions,'incomeloss_property': this.investmentDeclarationForm.getRawValue().incomeloss,'income_from_othersource': this.investmentDeclarationForm.getRawValue().otherincome,'tax_regime': data.tax_regime,'declared_datail': data.declared_datail}
    if(this.from=='behalf'){
      saveData['employee_id'] = this.empId;
      this.activityService.investmentDeclaration(saveData).subscribe((res:any)=>{
        this.router.navigate(['manage-activities']);

      })
    }
    else{
    this.investmentService.createInvestment({'proposed_confirm':false, 'employee':null, 'hra80gg': this.investmentDeclarationForm.getRawValue().hra80gg.hra80gg, 'chapter4a80c': this.investmentDeclarationForm.getRawValue().chapt2,'chapter4adeduction': this.investmentDeclarationForm.getRawValue().deductions,'incomeloss_property': this.investmentDeclarationForm.getRawValue().incomeloss,'income_from_othersource': this.investmentDeclarationForm.getRawValue().otherincome,'tax_regime': data.tax_regime,'declared_datail': data.declared_datail},'').subscribe((res:any)=>{
        this.router.navigate(['request']);
      });
    }
  }

  /********* HRA80GG VALIDATION FUNCTION ****************/
  public hra80ggValidation(data: any):void {

  }

  /********* CHAPT2 VALIDATION FUNCTION ****************/
  public chap2Validation(data: any):void {
    if( this.investmentDeclarationForm['controls'].chapt2.get(data.checkCn)?.value==true){

      this.investmentDeclarationForm['controls'].chapt2.get(data.amountCn)?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      this.investmentDeclarationForm['controls'].chapt2.get(data.amountCn)?.updateValueAndValidity();
    }else if(this.investmentDeclarationForm['controls'].chapt2.get(data.checkCn)?.value==false){
      this.investmentDeclarationForm['controls'].chapt2.get(data.amountCn)?.clearValidators();
      this.investmentDeclarationForm['controls'].chapt2.get(data.amountCn)?.updateValueAndValidity();
    }
  }

  /********* DEDUCTION VALIDATION FUNCTION ****************/
  public deductionValidation(data: any):void {

    if(this.investmentDeclarationForm['controls'].deductions.get('employee_additional_contribution')?.value==true){
      this.investmentDeclarationForm['controls'].deductions.get('employee_contribution_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      this.investmentDeclarationForm['controls'].deductions.get('employee_contribution_amount')?.updateValueAndValidity();
    }else{
      this.investmentDeclarationForm['controls'].deductions.get('employee_contribution_amount')?.clearValidators();
      this.investmentDeclarationForm['controls'].deductions.get('employee_contribution_amount')?.updateValueAndValidity();
    }if(this.investmentDeclarationForm['controls'].deductions.get('employer_contribution')?.value==true){
      this.shortDeductionsValidation('employer_contribution_amount',0);
    }else{
      this.shortDeductionsValidation('employer_contribution_amount',1);
    }if(this.investmentDeclarationForm['controls'].deductions.get('tta_ttb')?.value==true){
      if(this.employeeAge <= 60)
        this.investmentDeclarationForm['controls'].deductions.get('tta_ttb_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      else
        this.investmentDeclarationForm['controls'].deductions.get('tta_ttb_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      this.investmentDeclarationForm['controls'].deductions.get('tta_ttb_amount')?.updateValueAndValidity();
    }else{
      this.investmentDeclarationForm['controls'].deductions.get('tta_ttb_amount')?.clearValidators();
      this.investmentDeclarationForm['controls'].deductions.get('tta_ttb_amount')?.updateValueAndValidity();
    }if(this.investmentDeclarationForm['controls'].deductions.get('interest_education_loan')?.value==true){
      this.shortDeductionsValidation('interest_education_amount',0);
    }else if(this.investmentDeclarationForm['controls'].deductions.get('interest_education_loan')?.value==false){
      this.shortDeductionsValidation('interest_education_amount',1);
    }if(this.investmentDeclarationForm['controls'].deductions.get('interest_home_loan_80eea')?.value==true){
      this.shortDeductionsValidation('interest_home_loan_amount80ee',0);
    }else{
      this.shortDeductionsValidation('interest_home_loan_amount80ee',1);
    }if(this.investmentDeclarationForm['controls'].deductions.get('medical_treatment_handicapped')?.value==true){

      if(this.investmentDeclarationForm.getRawValue().deductions['disabled_severely'] == false){
        this.investmentDeclarationForm['controls'].deductions.get('handicapped_dependent_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      }else{
        this.investmentDeclarationForm['controls'].deductions.get('handicapped_dependent_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      }
      this.investmentDeclarationForm['controls'].deductions.get('handicapped_dependent_amount')?.updateValueAndValidity();
    }else{
      this.investmentDeclarationForm['controls'].deductions.get('handicapped_dependent_amount')?.clearValidators();
      this.investmentDeclarationForm['controls'].deductions.get('handicapped_dependent_amount')?.updateValueAndValidity();
    }if(this.investmentDeclarationForm['controls'].deductions.get('medical_expenditure')?.value==true){

      if(this.investmentDeclarationForm.getRawValue().deductions['year_60_more'] == false){
        this.investmentDeclarationForm['controls'].deductions.get('medical_expenditure_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      }else{
        this.investmentDeclarationForm['controls'].deductions.get('medical_expenditure_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      }
      this.investmentDeclarationForm['controls'].deductions.get('issue')?.setValidators([Validators.required]);
      this.investmentDeclarationForm['controls'].deductions.get('medical_expenditure_amount')?.updateValueAndValidity();
      this.investmentDeclarationForm['controls'].deductions.get('issue')?.updateValueAndValidity();
    }else{
      this.investmentDeclarationForm['controls'].deductions.get('medical_expenditure_amount')?.clearValidators();
      this.investmentDeclarationForm['controls'].deductions.get('issue')?.clearValidators();
      this.investmentDeclarationForm['controls'].deductions.get('medical_expenditure_amount')?.updateValueAndValidity();
      this.investmentDeclarationForm['controls'].deductions.get('issue')?.updateValueAndValidity();
    }if(this.investmentDeclarationForm['controls'].deductions.get('self_suffering_disability')?.value==true){

      if(this.investmentDeclarationForm.getRawValue().deductions['self_disabled_severely'] == false){
        this.investmentDeclarationForm['controls'].deductions.get('self_suffering_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      }else{
        this.investmentDeclarationForm['controls'].deductions.get('self_suffering_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      }
      this.investmentDeclarationForm['controls'].deductions.get('self_suffering_amount')?.updateValueAndValidity();
    }else{
      this.investmentDeclarationForm['controls'].deductions.get('self_suffering_amount')?.clearValidators();
      this.investmentDeclarationForm['controls'].deductions.get('self_suffering_amount')?.updateValueAndValidity();
    }if(this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause100')?.value==true){
       if(this.investmentDeclarationForm.getRawValue().deductions['cash_other100'] == false){
        this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause100_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      }else{
        this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause100_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      }
      this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause100_amount')?.updateValueAndValidity();
    }else{
      this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause100_amount')?.clearValidators();
      this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause100_amount')?.updateValueAndValidity();
    }

    if(this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause_50')?.value==true){
      if(this.investmentDeclarationForm.getRawValue().deductions['cash_other50'] == false){
       this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause50_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
     }else{
       this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause50_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
     }
     this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause50_amount')?.updateValueAndValidity();
   }else{
     this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause50_amount')?.clearValidators();
     this.investmentDeclarationForm['controls'].deductions.get('donate_social_cause50_amount')?.updateValueAndValidity();
   }

    if(this.investmentDeclarationForm['controls'].deductions.get('royalty_patent')?.value==true){
      this.investmentDeclarationForm['controls'].deductions.get('royalty_patent_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      this.investmentDeclarationForm['controls'].deductions.get('royalty_patent_amount')?.updateValueAndValidity();
    }else{
      this.investmentDeclarationForm['controls'].deductions.get('royalty_patent_amount')?.clearValidators();
      this.investmentDeclarationForm['controls'].deductions.get('royalty_patent_amount')?.updateValueAndValidity();
    }if(this.investmentDeclarationForm['controls'].deductions.get('contribution_individual_policalparties')?.value==true){
      this.shortDeductionsValidation('contribution_policalparties_amount',0);
    }else{
      this.shortDeductionsValidation('contribution_policalparties_amount',1);
    }if(this.investmentDeclarationForm['controls'].deductions.get('gga80')?.value==true){

      if(this.investmentDeclarationForm.getRawValue().deductions['cash_other_80gga'] == false){
        this.investmentDeclarationForm['controls'].deductions.get('total_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
        this.investmentDeclarationForm['controls'].deductions.get('check_no_transaction_80gga')?.setValidators([Validators.required]);
        this.investmentDeclarationForm['controls'].deductions.get('date')?.setValidators([Validators.required]);
      }else{
        this.investmentDeclarationForm['controls'].deductions.get('total_amount')?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
        this.investmentDeclarationForm['controls'].deductions.get('check_no_transaction_80gga')?.clearValidators();
        this.investmentDeclarationForm['controls'].deductions.get('date')?.clearValidators();
      }
      this.investmentDeclarationForm['controls'].deductions.get('total_amount')?.updateValueAndValidity();
      this.investmentDeclarationForm['controls'].deductions.get('check_no_transaction_80gga')?.updateValueAndValidity();
      this.investmentDeclarationForm['controls'].deductions.get('date')?.updateValueAndValidity();
    }else{
      this.investmentDeclarationForm['controls'].deductions.get('total_amount')?.clearValidators();
      this.investmentDeclarationForm['controls'].deductions.get('date')?.clearValidators();
      this.investmentDeclarationForm['controls'].deductions.get('check_no_transaction_80gga')?.clearValidators();
      this.investmentDeclarationForm['controls'].deductions.get('total_amount')?.updateValueAndValidity();
      this.investmentDeclarationForm['controls'].deductions.get('date')?.updateValueAndValidity();
      this.investmentDeclarationForm['controls'].deductions.get('check_no_transaction_80gga')?.updateValueAndValidity();
    }

    if(this.investmentDeclarationForm['controls'].deductions.get('health_insurance_parents80d')?.value==true){
      this.shortDeductionsValidation('health_insurance_parents80d_amount',0);
    }else{
      this.shortDeductionsValidation('health_insurance_parents80d_amount',1);
    }
    if(this.investmentDeclarationForm['controls'].deductions.get('health_insurance_selfspouse80d')?.value==true){
      this.shortDeductionsValidation('health_insurance_selfspouse80d_amount',0);
    }else{
      this.shortDeductionsValidation('health_insurance_selfspouse80d_amount',1);
    }
    if(this.investmentDeclarationForm['controls'].deductions.get('medicalbill_senior80d')?.value==true){
      this.shortDeductionsValidation('medicalbill_senior80d_amount',0);
    }else{
      this.shortDeductionsValidation('medicalbill_senior80d_amount',1);
    }
    if(this.investmentDeclarationForm['controls'].deductions.get('health_checkup_parents80d')?.value==true){
      this.shortDeductionsValidation('health_checkup_parents80d_amount',0);
    }else{
      this.shortDeductionsValidation('health_checkup_parents80d_amount',1);
    }
    if(this.investmentDeclarationForm['controls'].deductions.get('health_checkup_selfspouse80d')?.value==true){
      this.shortDeductionsValidation('health_checkup_selfspouse80d_amount',0);
    }else{
      this.shortDeductionsValidation('health_checkup_selfspouse80d_amount',1);
    }
    if(this.investmentDeclarationForm['controls'].deductions.get('interest_electrical_vehicle_loan_80eeb')?.value==true){
      this.shortDeductionsValidation('interest_electrical_vehicle_loan_amount',0);
    }else{
      this.shortDeductionsValidation('interest_electrical_vehicle_loan_amount',1);
    }
    if(this.investmentDeclarationForm['controls'].deductions.get('interest_home_loan_80eea')?.value==true){
      this.shortDeductionsValidation('interest_home_loan_amount80eea',0);
    }else{
      this.shortDeductionsValidation('interest_home_loan_amount80eea',1);
    }

    if(this.investmentDeclarationForm['controls'].deductions.get('interest_home_loan_80ee')?.value==true){
      this.shortDeductionsValidation('interest_home_loan_amount80ee',0);
    }else{
      this.shortDeductionsValidation('interest_home_loan_amount80ee',1);
    }
  }

  shortDeductionsValidation(amount: any, stat: any){
    if(stat == 0){
      // if(amount == 'interest_home_loan_amount')
      //   this.investmentDeclarationForm['controls'].deductions.get(amount)?.setValidators([Validators.required,digitdecimalValidator()]);
      // else{
        this.investmentDeclarationForm['controls'].deductions.get(amount)?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      // }
    this.investmentDeclarationForm['controls'].deductions.get(amount)?.updateValueAndValidity();
    }else{
      this.investmentDeclarationForm['controls'].deductions.get(amount)?.clearValidators();
      this.investmentDeclarationForm['controls'].deductions.get(amount)?.updateValueAndValidity();
    }
  }

  /********* OTHER INCOME VALIDATION FUNCTION ****************/
  public otherincome(data: any):void {
    if( this.investmentDeclarationForm['controls'].otherincome.get(data.checkCn)?.value==true){

      this.investmentDeclarationForm['controls'].otherincome.get(data.amountCn)?.setValidators([Validators.required,digitdecimalValidator(),Validators.maxLength(15)]);
      this.investmentDeclarationForm['controls'].otherincome.get(data.amountCn)?.updateValueAndValidity();
      }else if(this.investmentDeclarationForm['controls'].otherincome.get(data.checkCn)?.value==false){
        this.investmentDeclarationForm['controls'].otherincome.get(data.amountCn)?.clearValidators();
        this.investmentDeclarationForm['controls'].otherincome.get(data.amountCn)?.updateValueAndValidity();
      }
  }

  /********* RUTER CHANGE FUNCTION ****************/
  onNavChange(changeEvent: NgbNavChangeEvent){

    if (changeEvent.nextId === 2) {
      if (this.investmentDeclarationForm['controls'].hra80gg.invalid) {
        changeEvent.preventDefault();
      }else{
        this.chapt2Setvalue('null');
        this.tab2touched =true;
      }
    }
    else  if (changeEvent.nextId === 3) {
      if (this.investmentDeclarationForm['controls'].chapt2.invalid || this.investmentDeclarationForm['controls'].hra80gg.invalid || this.tab2touched == false) {
        changeEvent.preventDefault();
      }else{
        this.deductionsSetvalue('null');
        this.tab3touched = true;
      }
    }else  if (changeEvent.nextId === 4) {
      if (this.investmentDeclarationForm['controls'].deductions.invalid || this.investmentDeclarationForm['controls'].chapt2.invalid || this.investmentDeclarationForm['controls'].hra80gg.invalid || this.tab3touched == false) {
        changeEvent.preventDefault();
      }else{
        this.incomeLossSetValue('null');
        this.tab4touched = true;
      }
    }else  if (changeEvent.nextId === 5) {
      if (this.investmentDeclarationForm['controls'].incomeloss.invalid || this.investmentDeclarationForm['controls'].deductions.invalid || this.investmentDeclarationForm['controls'].chapt2.invalid || this.investmentDeclarationForm['controls'].hra80gg.invalid || this.tab4touched == false) {
        changeEvent.preventDefault();
      }else{
        this.otherincomeSetvalue('null');
        this.tab5touched = true;
      }
    }else  if (changeEvent.nextId === 6) {
      if (this.investmentDeclarationForm['controls'].otherincome.invalid || this.investmentDeclarationForm['controls'].incomeloss.invalid || this.investmentDeclarationForm['controls'].deductions.invalid || this.investmentDeclarationForm['controls'].chapt2.invalid || this.investmentDeclarationForm['controls'].hra80gg.invalid || this.tab5touched == false) {
        changeEvent.preventDefault();
      }else{
        this.taxRegimeCalculationFunction();
      }
    }
  }

  /********* CHAPT2 SET VALUE FUNCTIONS ****************/
  chapt2Setvalue(type:any){
    this.shortChapt2SetValue('employee_contribute_pf','employee_contribute_pf_amount',type);
    this.shortChapt2SetValue('contribution_vpf','contribution_vpf_amount',type);
    this.shortChapt2SetValue('previous_employer_pf','previous_employer_pf_amount',type);

    this.shortChapt2SetValue('contribution_15y_ppf','ppf_amount',type);
    this.shortChapt2SetValue('five_year_POTD','POTD_amount',type);
    this.shortChapt2SetValue('subscribe_national_saving','nsc_amount',type);
    this.shortChapt2SetValue('deduction_contribute_NPS','nps_amount',type);
    this.shortChapt2SetValue('equity_linked_saving','ELSS_amount',type);
    this.shortChapt2SetValue('ULIP','ULIP_amount',type);
    this.shortChapt2SetValue('interest_NSC','interest_NSC_amount',type);
    this.shortChapt2SetValue('child_education_tuition_fees','tuition_fees',type);
    this.shortChapt2SetValue('repay_house_principal','repay_house_amount',type);
    this.shortChapt2SetValue('stampduty_purchase_house','stampduty_amount',type);
    this.shortChapt2SetValue('saukanya_samridhi_yojana','ssy_amount',type);

    this.shortChapt2SetValue('nabard_rural_bond','nabard_rural_bond_amount',type);
    this.shortChapt2SetValue('lic','lic_amount',type);
    this.shortChapt2SetValue('annuity','annuity_amount',type);

  }

  shortChapt2SetValue(check : any, amount : any,type:any){

    if(this.investmentDeclarationForm['controls'].chapt2.get(check)?.value == false){
      if(type == 'zero')
        this.investmentDeclarationForm['controls'].chapt2.get(amount)?.setValue(0);
      else
        this.investmentDeclarationForm['controls'].chapt2.get(amount)?.setValue('');
    }else{
      this.investmentDeclarationForm['controls'].chapt2.get(amount)?.setValue(Number(this.investmentDeclarationForm['controls'].chapt2.get(amount)?.value));
    }

  }

  /********* HRA80GG SET VALUE FUNCTION ****************/
  hr80ggSetvalue(){
    if(this.investmentDeclarationForm.getRawValue().hra80gg.hra80gg.length > 0){
      for(let k=0;k<this.investmentDeclarationForm.getRawValue().hra80gg.hra80gg.length;k++){
        let data = (this.investmentDeclarationForm.getRawValue().hra80gg.hra80gg[k]['amount'] != null) ? (Number(this.investmentDeclarationForm.getRawValue().hra80gg.hra80gg[k]['amount'])) : 0;
        (<FormArray>this.investmentDeclarationForm.get("hra80gg")?.get("hra80gg")).at(k).get('amount')?.setValue(data);
      }
    }
  }

  /********* INCOME LOSS SET VALUE FUNCTIONs ****************/
  incomeLossSetValue(type:any){

    this.shortIncomeLossSetValue('incomefrom_self_occupiedproperty','intreston_houseloan_amount',type);

    if(this.investmentDeclarationForm.getRawValue().incomeloss.loss_lender.length > 0){
      for(let k=0;k<this.investmentDeclarationForm.getRawValue().incomeloss.loss_lender.length;k++){
        let data = (this.investmentDeclarationForm.getRawValue().incomeloss.loss_lender[k]['intrestpaid_houseloan_amount'] != null) ? ((this.investmentDeclarationForm.getRawValue().incomeloss.loss_lender[k]['intrestpaid_houseloan_amount'])) : (this.investmentDeclarationForm.getRawValue().incomeloss.loss_lender[k]['intrestpaid_houseloan_amount'] != "") ? ((this.investmentDeclarationForm.getRawValue().incomeloss.loss_lender[k]['intrestpaid_houseloan_amount'])) : 0;

        (<FormArray>this.investmentDeclarationForm.get("incomeloss")?.get("loss_lender")).at(k).get('intrestpaid_houseloan_amount')?.setValue(Number(data));
      }
    }
  }

  shortIncomeLossSetValue(check : any, amount : any, type:any){
    if(this.investmentDeclarationForm['controls'].incomeloss.get(check)?.value == false){
      if(type == 'zero')
        this.investmentDeclarationForm['controls'].incomeloss.get(amount)?.setValue(0);
      else
        this.investmentDeclarationForm['controls'].incomeloss.get(amount)?.setValue('');
    }else{
      this.investmentDeclarationForm['controls'].incomeloss.get(amount)?.setValue(Number(this.investmentDeclarationForm['controls'].incomeloss.get(amount)?.value));
    }
  }

  /********* DEDUCTION SET VALUE FUNCTIONs ****************/
  deductionsSetvalue(type:any){
    this.shortDeductionsSetValue('employer_contribution','employer_contribution_amount',type);

    this.shortDeductionsSetValue('self_paid_80D','self_paid_80D_amount',type);
    this.shortDeductionsSetValue('employee_additional_contribution','employee_contribution_amount',type);
    this.shortDeductionsSetValue('donate_social_cause100','donate_social_cause100_amount',type);
    this.shortDeductionsSetValue('tta_ttb','tta_ttb_amount',type);
    this.shortDeductionsSetValue('interest_education_loan','interest_education_amount',type);
    this.shortDeductionsSetValue('medical_treatment_handicapped','handicapped_dependent_amount',type);
    this.shortDeductionsSetValue('medical_expenditure','medical_expenditure_amount',type);
    this.shortDeductionsSetValue('stampduty_purchase_house','stampduty_amount',type);
    this.shortDeductionsSetValue('self_suffering_disability','self_suffering_amount',type);
    this.shortDeductionsSetValue('royalty_patent','royalty_patent_amount',type);
    this.shortDeductionsSetValue('contribution_individual_policalparties','contribution_policalparties_amount',type);
    this.shortDeductionsSetValue('gga80','total_amount',type);
    this.shortDeductionsSetValue('interest_home_loan_80ee','interest_home_loan_amount80ee',type);

    this.shortDeductionsSetValue('health_insurance_selfspouse80d','health_insurance_selfspouse80d_amount',type);
    this.shortDeductionsSetValue('health_insurance_parents80d','health_insurance_parents80d_amount',type);
    this.shortDeductionsSetValue('health_checkup_selfspouse80d','health_checkup_selfspouse80d_amount',type);
    this.shortDeductionsSetValue('health_checkup_parents80d','health_checkup_parents80d_amount',type);
    this.shortDeductionsSetValue('medicalbill_senior80d','medicalbill_senior80d_amount',type);
    this.shortDeductionsSetValue('interest_home_loan_80eea','interest_home_loan_amount80eea',type);
    this.shortDeductionsSetValue('interest_electrical_vehicle_loan_80eeb','interest_electrical_vehicle_loan_amount',type);
    this.shortDeductionsSetValue('donate_social_cause_50','donate_social_cause50_amount',type);

  }

  shortDeductionsSetValue(check : any, amount : any,type:any){

    if(this.investmentDeclarationForm['controls'].deductions.get(check)?.value == false){
      if(type == 'zero')
        this.investmentDeclarationForm['controls'].deductions.get(amount)?.setValue(0);
      else
        this.investmentDeclarationForm['controls'].deductions.get(amount)?.setValue('');
    }else{
      this.investmentDeclarationForm['controls'].deductions.get(amount)?.setValue(Number(this.investmentDeclarationForm['controls'].deductions.get(amount)?.value));
    }

  }

  /********* OTHER INCOME SET VALUE FUNCTIONs ****************/
  otherincomeSetvalue(type:any){

    this.shortOtherIncomeSetValue('incomefrom_othersource','othersource_amount',type);
    this.shortOtherIncomeSetValue('interest_earned_sbaccount','sb_account_amount',type);
    this.shortOtherIncomeSetValue('source_80TTA_80TTB','source_80TTA_80TTB_amount',type);
    this.shortOtherIncomeSetValue('intrest_earned','intrest_earned_amount',type);

  }

  shortOtherIncomeSetValue(check : any, amount : any, type:any){

    if(this.investmentDeclarationForm['controls'].otherincome.get(check)?.value == false){
      if(type == 'zero')
        this.investmentDeclarationForm['controls'].otherincome.get(amount)?.setValue(0);
      else
        this.investmentDeclarationForm['controls'].otherincome.get(amount)?.setValue('');
    }else{
      this.investmentDeclarationForm['controls'].otherincome.get(amount)?.setValue(Number(this.investmentDeclarationForm['controls'].otherincome.get(amount)?.value))
    }

  }

  get hra80ggForm() {
    return this.investmentDeclarationForm.get('hra80gg') as FormGroup;
  }
  get chapt2Form(){
    return this.investmentDeclarationForm.get('chapt2') as FormGroup;
  }
  get deductionsForm(){
    return this.investmentDeclarationForm.get('deductions') as FormGroup;
  }
  get incomeLossForm(){
    return this.investmentDeclarationForm.get('incomeloss') as FormGroup;
  }
  get otherIncomeForm(){
    return this.investmentDeclarationForm.get('otherincome') as FormGroup;
  }

  donate_80gs() : FormArray {
    return this.deductionsForm.get("donate_80g") as FormArray
  }

  newDonate_80g(): FormGroup {
     return this.formBuilder.group({
      "exemption"                 : null,
      "name"                      : [null],
			"pan"                       : [null],
			"address"                   : [null],
      "payment_mode"              : null,
			"check_no_transaction_id"   : null,
      "payment_date"              : null
    })
  }

  addDonate_80g() {
    this.donate_80gs().push(this.newDonate_80g());
  }

  removeAllDonate_80gs(){
    if(this.investmentDeclarationForm.getRawValue().deductions.donate_80g.length > 0){
      while(this.investmentDeclarationForm.getRawValue().deductions.donate_80g.length !== 0){
        this.donate_80gs().removeAt(0);
      }
    }
  }

  deduction_80ggas() : FormArray {
    return this.otherIncomeForm.get("deduction_80gga") as FormArray
  }

  newDeduction_80gga(): FormGroup {
    return this.formBuilder.group({
      "check_no_transaction_id" : [null],
			"date"                    : null,
    })
  }

  addDeduction_80gga() {
    this.deduction_80ggas().push(this.newDeduction_80gga());
  }

  salaryStructureConfig(){
    this.loader = true;
    setTimeout(() => {
      if(this.from=='behalf'){
        this.activityService.getReqCount(this.empId).subscribe((res:any)=>{
          this.setFormValue(res)
        })
      }
      else{

        this.investmentService.requestDetails().subscribe((res:any)=>{
          this.setFormValue(res);
        });
      }
    }, 2000);
  }
  setFormValue(res:any){
    this.loader = false;
        this.investmentDetails  = res;
        if(res.count_info.hasOwnProperty('max_count_limit')){
          this.investmentCnt= res.count_info.max_count_limit;
        }if(res.count_info.hasOwnProperty('assigned_salary_data')){
          if(res.count_info.assigned_salary_data.hasOwnProperty('tax_regime')){
            this.taxRegima= res.count_info.assigned_salary_data.tax_regime;

            let nps_amount_total = Number(res.count_info.assigned_salary_data.nps_amount + res.count_info.assigned_salary_data.prev_employer_nps_deduction)
            if(nps_amount_total>0){
              // condition added for previous employer nps scenario
              this.investmentDeclarationForm['controls'].deductions.get('employer_contribution')?.setValue(true);
            }else{
              this.investmentDeclarationForm['controls'].deductions.get('employer_contribution')?.setValue(res.count_info.assigned_salary_data.employer_contribution_nps);
            }
            this.investmentDeclarationForm['controls'].deductions.get('employer_contribution_amount')?.setValue(nps_amount_total);

            this.investmentDeclarationForm['controls'].chapt2.get('employee_contribute_pf')?.setValue(res.count_info.assigned_salary_data.provident_fund);
            this.investmentDeclarationForm['controls'].chapt2.get('employee_contribute_pf_amount')?.setValue(Number(res.count_info.assigned_salary_data.pf_amount));

            this.investmentDeclarationForm['controls'].chapt2.get('contribution_vpf')?.setValue(res.count_info.assigned_salary_data.volentary_provident_fund);
            this.investmentDeclarationForm['controls'].chapt2.get('contribution_vpf_amount')?.setValue(Number(res.count_info.assigned_salary_data.amount));

            this.investmentDeclarationForm['controls'].chapt2.get('previous_employer_pf')?.setValue(res.count_info.assigned_salary_data.employer_contribution_nps);
            this.investmentDeclarationForm['controls'].chapt2.get('previous_employer_pf_amount')?.setValue(Number(res.count_info.assigned_salary_data.prev_emplyer_pf));

          }else{
            this.taxRegima= res.count_info.assigned_salary_data.AssignSalaryStructure;
          }
        }
        if(res.count_info.hasOwnProperty('request_count_info')){
          if(res.count_info.request_count_info.hasOwnProperty('Proposed_Investment_Declaration')){
            this.proposed_cnt= res.count_info.request_count_info.Proposed_Investment_Declaration;
          }if(res.count_info.request_count_info.hasOwnProperty('Confirmed_Investment_Declaration')){
            this.confirmed_cnt= res.count_info.request_count_info.Confirmed_Investment_Declaration;
          }
        }
        if(res.count_info.hasOwnProperty('employee_data')){
          this.employeeAge = res.count_info.employee_data.age;
        }
  }
  // NEW

  lenderIncome() : FormArray {
    return this.incomeLossForm.get("incomeself_lender") as FormArray
  }

  newLenderIncome(): FormGroup {
    return this.formBuilder.group({
      "lender_name" : [null],
			"lender_pan"  : [null],
    })
  }

  lenderLoss() : FormArray {
    return this.incomeLossForm.get("loss_lender") as FormArray
  }

  newLenderLoss(): FormGroup {
      return this.formBuilder.group({
        "annual_rent_received"         : [null],
        "muncipal_taxpaid"             : [null],
        "netvalue_ab"                  : [null],
        "standard_deduction"           : [null],
        "repaying_homeloan_property"   : false,
        "intrestpaid_houseloan_amount" : [null],
        "lender_name"                  : [null],
        "lender_pan"                   : [null],
        "net_income_houseproperty"     : [null]
      })
  }

  hra80ggs() : FormArray {
    return this.hra80ggForm.get("hra80gg") as FormArray
  }

  newHra80gg(): FormGroup {
    return this.formBuilder.group({
      "select_one"        : true,
      "from_month"        : null,
      "to_month"          : null,
      "amount"            : [null],
      "landlord_name"     : [null],
      "landlord_pan"      : [null],
      "landlord_address"  : [null],
      "landlord_document" : [null],
      "city"              : null,
      "from_year"        : [null],
      "to_year"          : [null],
    })
  }

  donate_80gs100() : FormArray {
    return this.deductionsForm.get("donate_80g100") as FormArray
  }

  newDonate_80gs100(): FormGroup {
     return this.formBuilder.group({
      "exemption"                 : null,
      "name"                      : [null],
			"pan"                       : [null],
			"address"                   : [null],
      "payment_mode"              : null,
			"check_no_transaction_id"   : null,
      "payment_date"              : null
    })
  }

  donate_80gs50() : FormArray {
    return this.deductionsForm.get("donate_80g50") as FormArray
  }

  newDonate_80gs50(): FormGroup {
     return this.formBuilder.group({
      "exemption"                 : null,
      "name"                      : [null],
			"pan"                       : [null],
			"address"                   : [null],
      "payment_mode"              : null,
			"check_no_transaction_id"   : null,
      "payment_date"              : null
    })
  }

  // END
}
